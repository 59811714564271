<template>
  <div class="Closing" >
    <div class="container">

      <h1 class="gavc-h1 text-center">Closing</h1>

      <button
        class="gavc-btn gavc-btn--bone"
        @click="$router.push(`/audit/paiement-prix/${closing.candidature.audit.paiement_prix_id}`)"
      >
        <i class="gavc-icon gavc-icon-arrow-left"></i> Étape précédente (Audit)
      </button>

      <p class=" mt-4 gavc-chapo">
        <strong>Félicitations</strong>, vous avez terminé votre parcours sur Garance à vos côtés et
        pouvez générer automatiquement votre acte de cession
      </p>

      <p class="gavc-p">
        Ce projet est généré sur la base des renseignements produits dans les phases préalables et
        doit faire l’objet d’une relecture attentive.
      </p>

      <div
        class="gavc-field mt-3"
        v-bind:class="{ 'is-error': closing.date_effect_cession && $v.closing.date_effect_cession.$invalid }"
      >
        <label class="gavc-label" for="closing.date_effect_cession-input">
          Veuillez renseigner la date d'effet de la cession
          <span class="gavc-required"> * </span>
        </label>
        <div class="gavc-field gavc-field--icon">
          <input
            @input="$v.closing.date_effect_cession.$touch()"
            class="gavc-input gavc-input--icon gavc-input--calendar"
            id="closing.date_effect_cession-input"
            type="date"
            v-model="closing.date_effect_cession"
          >
          <i class="gavc-icon gavc-icon-calendar" ></i>
        </div>
      </div>


      <div v-if="!$v.closing.date_effect_cession.$invalid" class="row mt-4">
        <div class="col-12 text-center">
          <button
            class="gavc-btn gavc-btn--bone"
            @click="downloadRapportCession"
          >
            Générer le rapport de cession
          </button>

          <button
            class="gavc-btn"
            @click="downloadActeCession"
          >
            Générer l'acte de cession
          </button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

import { APIConnected } from '@/api/APIConnected'
import { isDate } from '@/validators/validators'
import userMixins from '@/mixins/userMixins'

const apiConnectedService = new APIConnected()


export default {
  name: 'Closing',
  mixins: [
    userMixins,
    validationMixin
  ],
  data: function () {
    return {
      closing: {
        candidature: {
          audit: {
            id: ''
          },
          description: '',
          offre_cession: {
            entreprise_cedee: {
              cedant: {
                phone_number: '',
                user: {
                  first_name: '',
                  last_name: '',
                  username: ''
                }
              },
              name: '',
              siren: ''
            },
            price: '',
            title: '',

          },
          repreneur: {
            phone_number: '',
            user: {
              first_name: '',
              last_name: '',
              username: ''
            }
          },
          state: '3VALIDATED'
        },
        date_effect_cession: null,
      },

    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
  },
  created: function() {
    this.$dayjs.locale('fr')
    this.getClosing()
  },

  validations: {
    closing: {
      date_effect_cession: {
        required,
        validDate(value) {
          return isDate(value)
        }
      },
    }
  },
  methods: {

    downloadActeCession () {
      delete this.closing.candidature
      this.$store.commit('loadFromAPI')
      apiConnectedService.modifyClosing(this.token, this.$route.params.closingId, this.closing)

      .then((result) => {
        this.closing = result.data
        apiConnectedService.downloadClosingDocument(this.token, this.closing.id)
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', "acte_de_cession.docx"); //or any other extension
            document.body.appendChild(link);
            link.click();
          }, () => {
            this.$store.commit('openGlobalErrorModal')
          })
      })
      .catch((error) => {
        this.$store.commit('openGlobalErrorModal')
        throw new Error('Closing modifyClosing API Error : ' + String(error))
      })
      .finally(() => {
        this.$store.commit('endLoadingFromAPI')
      })
    },

    downloadRapportCession () {
      this.$store.commit('loadFromAPI')
      apiConnectedService.downloadRapportCession(this.token, this.closing.id)
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', "rapport_cession.pdf"); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          this.$store.commit('openGlobalErrorModal')
          throw new Error('Closing modifyClosing API Error : ' + String(error))
        })
        .finally(() => {
          this.$store.commit('endLoadingFromAPI')
        })
    },

    getClosing () {
      this.$store.commit('loadFromAPI')
      apiConnectedService.getClosing(this.token, this.$route.params.closingId)
      .then((result) => {
        this.closing = result.data
      })
      .catch((error) => {
        this.$store.commit('openGlobalErrorModal')
        throw new Error('Closing getClosing API Error : ' + String(error))
      })
      .finally(() => {
        this.$store.commit('endLoadingFromAPI')
      })
    }
  }
}
</script>
